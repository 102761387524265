import { Routes, Route } from "react-router-dom";
import Editor from './components/editPage/editor';
import PageNotFound from './components/pageNotFound';
import AmmoOP from './components/ammoOP/ammoOP';

function App () {

    return(
        <Routes>
            <Route path="/" element={<AmmoOP />}></Route>
            <Route path="edit" element={<Editor />}></Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default App;
