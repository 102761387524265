import { Button } from '@mui/material';
import '../../styles/footer.css'

export default function Footer(props: any) {
    const classAdd : string = props.classAdd
    const classTallAdd : string = props.classTallAdd
    const isModalOpen : boolean = props.isModalOpen
    const setModalOpen : React.Dispatch<React.SetStateAction<boolean>> = props.setModalOpen
    const tooNarrow : boolean = props.tooNarrow
    const tooSmall : boolean = props.tooSmall

    return(
        <div className={`footer${classAdd}${classTallAdd}`}>
            {!tooNarrow && 
                <img src="logowithtext.png" className={`footerimage${classAdd}`} alt="footerimage"/>
            }
                {tooSmall && 
                <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                    height: '32px', marginLeft: '10px', float: 'left', color: 'white', borderRadius: "5px", position: "absolute", bottom: "calc(4.5vh - 16px)"}}
                    onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us
                </Button>
                }
        </div>
    )
}
