import { ReactElement } from 'react';
import { IStrategy } from '../../../interfaces';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { IconButton, Collapse, Box } from '@mui/material';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Table from '@mui/material/Table';
import ClearIcon from '@mui/icons-material/Clear';
import { ARMY_DODICS } from '../../../constants';

export default function MobileCart( props : any ): ReactElement[] {
    const elements: ReactElement[] = [];
    const open : boolean = props.open
    const savedCalcs : {[charRep: string]: IStrategy[];}[] = props.savedCalcs
    const savedTitles : ReactElement<any, string | React.JSXElementConstructor<any>>[] = props.savedTitles
    const setSavedCalcs : React.Dispatch<React.SetStateAction<{[charRep: string]: IStrategy[];}[]>> = props.setSavedCalcs
    const setSavedTitles : React.Dispatch<React.SetStateAction<ReactElement<any, string | React.JSXElementConstructor<any>>[]>> = props.setSavedTitles
    const setOpen : React.Dispatch<React.SetStateAction<boolean>> = props.setOpen

    function tableMaker(calcs: { [charRep : string]: IStrategy[] }) {
        const tables: ReactElement[] = [];
        for (const key in calcs){
            for (let x = 0; x < ARMY_DODICS.length; x++) {
                if (calcs[key][0].charRep === ARMY_DODICS[x].charRep){
                    let sum = 0;
                    for (let z = 1; z < calcs[key].length; z++){
                        sum += calcs[key][z].count;
                    }
                    tables.push(
                        <TableRow sx={{ maxWidth:'95%' }}>
                            <TableCell sx={{ padding:'0' }} component="th" scope="row">
                                {sum.toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ padding:'1' }}>{ARMY_DODICS[x].ammoName}</TableCell>
                            <TableCell sx={{ padding:'1' }} align="left">{ARMY_DODICS[x].charRep}</TableCell>
                        </TableRow>
                    )
                }
            }
        }
        return (tables);
    }
    
    for (let i = 0; i < savedCalcs.length; i++){
        elements.push(
        <>
        <TableHead>
            <TableRow sx={{ maxWidth:'95%' }}>
                <TableCell sx={{ fontWeight: "bold", padding: '1' }}>
                    <IconButton  onClick={() => setOpen(!open)} sx={{ zIndex: 10 }}>
                        {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                    </IconButton>
                    {savedTitles[i]}
                </TableCell>
            </TableRow>
        </TableHead>
        <TableRow sx={{ maxWidth:'95%' }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                <Collapse in={open} sx={{ maxWidth: `95%` }} timeout="auto" unmountOnExit>
                    <Box sx={{ maxWidth: `95%` }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ padding:'0' }}>QTY</TableCell>
                                    <TableCell sx={{ padding:'1' }}>Ammunition</TableCell>
                                    <TableCell sx={{ padding:'1' }} align="right">DODIC </TableCell>
                                    <TableCell sx={{ padding:'1' }}>
                                        <div style={{ display: 'flex', marginTop: '25%' }}>
                                            {/* Potential edit button for each saved calc here */}
                                            {/* <BorderColorOutlinedIcon sx={{ fontSize: 'medium', cursor: 'pointer', "&:hover, &.Mui-focusVisible": { color: "cornflowerblue" }}} /> */}
                                            <ClearIcon sx={{ fontSize: 'large', cursor: 'pointer',  color: "red" }} 
                                                onClick={() => {
                                                    setSavedCalcs(savedCalcs.filter((item => item !== savedCalcs[i])))
                                                    if (savedTitles.length > 1) setSavedTitles(savedTitles.slice(0, i).concat(savedTitles.slice(i + 1, savedTitles.length)))
                                                    else setSavedTitles([])}}/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableMaker(savedCalcs[i])}
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
        </>)
    }
    return(elements)
}