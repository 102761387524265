import { ALL_CHAPTERS } from "../../constants";
import { Button } from "@mui/material";
import { ReactElement } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select/creatable'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Iterator from "./iterator";
import { components } from "react-select";
import MobileCart from "./cart/mobileCart";
import { IStrategy, Option } from "../../interfaces";
import {
    quickLinkMaker,
    buttonCreator,
    titleMaker,
    calculate
} from '../../helpers'
// import MobileDrawer from "./MobileDrawer";

function MainContent(props : any) {
    const tooSmall : boolean = props.tooSmall
    const chapterSelected : string | undefined = props.chapterSelected
    const savedTitles : ReactElement<any, string | React.JSXElementConstructor<any>>[] = props.savedTitles
    const savedCalcs : {[charRep: string]: IStrategy[];}[] = props.savedCalcs
    const eveValue : readonly Option[] = props.eveValue
    const catValue : Option | null = props.catValue
    const eventsValue : Option | null = props.eventsValue
    const weaponValue : Option | null = props.weaponValue
    const classAdd : string = props.classAdd
    const classTallAdd : string = props.classTallAdd
    const modifyPdf : (savedCalcs: {[charRep: string]: IStrategy[];}[]) => Promise<1 | undefined> = props.modifyPdf
    const setChapterSelected : React.Dispatch<React.SetStateAction<string | undefined>> = props.setChapterSelected
    const setSavedCalcs : React.Dispatch<React.SetStateAction<{[charRep: string]: IStrategy[];}[]>> = props.setSavedCalcs
    const setSavedTitles : React.Dispatch<React.SetStateAction<ReactElement<any, string | React.JSXElementConstructor<any>>[]>> = props.setSavedTitles
    const isEventsMulti : boolean = props.isEventsMulti
    const weaponOptions : {value: string;label: string;}[] = props.weaponOptions
    const setWeaponValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setWeaponValue
    const setCatValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setCatValue
    const setEveValue : React.Dispatch<React.SetStateAction<readonly Option[]>>= props.setEveValue
    const eventsOptions : {value: string;label: string;}[] = props.eventsOptions
    const setEventsValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setEventsValue
    const catOptions : {value: string;label: string;}[] = props.catOptions
    const eveOptions : {value: string;label: string;}[] = props.eveOptions
    const weaponCount : number = props.weaponCount
    const setweaponCount : React.Dispatch<React.SetStateAction<number>> = props.setweaponCount
    const iterationCount : number = props.iterationCount
    const setIterationCount : React.Dispatch<React.SetStateAction<number>> = props.setIterationCount
    const currentCalcs : {[charRep: string]: IStrategy[];}[] = props.currentCalcs
    const is10Added : boolean = props.is10Added
    const setTempWeaponValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setTempWeaponValue
    const setTempEventsValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setTempEventsValue
    const setTempCatValue : React.Dispatch<React.SetStateAction<Option | null>> = props.setTempCatValue
    const setTempEveValue : React.Dispatch<React.SetStateAction<readonly Option[]>> = props.setTempEveValue
    const setQuickSetClicked : React.Dispatch<React.SetStateAction<boolean>> = props.setQuickSetClicked
    const tooBig : boolean = props.tooBig
    const classBigAdd : string = props.classBigAdd
    const tooBigStyle = props.tooBigStyle
    let tooBigCalcTableStyle, tooBigCalcButtonStyle = {}
    if (tooBig){
        tooBigCalcTableStyle = {
            fontSize: '40px'
        }
        tooBigCalcButtonStyle = {
            fontSize: '1.25vw'
        }
    }
    function getCombatArms() {
        const sorted_chapters = []
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (ALL_CHAPTERS[i].tags.includes("Combat Arms"))
                sorted_chapters.push(ALL_CHAPTERS[i])
        return(sorted_chapters)
    }
    
    function getCSS() {
        const sorted_chapters = []
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (ALL_CHAPTERS[i].tags.includes("Combat (Service) Support"))
                sorted_chapters.push(ALL_CHAPTERS[i])
        return(sorted_chapters)
    }

    function getMisc() {
        const sorted_chapters = []
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (ALL_CHAPTERS[i].tags.includes("Special Use Case"))
                sorted_chapters.push(ALL_CHAPTERS[i])
        return(sorted_chapters)
    }

    function givePlaceholder(bool: boolean) {
        if(bool)
            return "Multiple Selections"
        else
            return "Select Training Exercises..."
    }

    const combatChapters = getCombatArms()
    const cssChapters = getCSS()
    const miscChapters = getMisc()
    const Option = (props: any) => { 
        return ( 
        <div> 
            <components.Option {...props}> 
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> 
                <label>{props.value}</label> 
            </components.Option> 
        </div> 
        ); 
    }; 

    return(
        <div className={`maincontent${classAdd}${classTallAdd}`}>
            {tooSmall &&
                <>
                    {/* {MobileDrawer(isMobileDrawerShowing, setIsMobileDrawerShowing)} */}
                    <div style={{fontSize: "24px", overflowY: "auto", overflowX: "auto", textAlign: "center"}}>
                        <span style={{color: "red"}}>TESTING PHASE</span>
                    </div>
                </>
            }
            {!chapterSelected &&
            <div className="branchcontainer">
                <div className={`chapselecttitle${classBigAdd}`}>Select Branch</div>
                <img src="underline.png" style={{width: "15vw"}}/>
                <div style={{overflowY: 'visible'}}>
                    <div className={`chaptertag${classBigAdd}`}>COMBAT ARMS</div><br/>
                    <div className={`flexbox${classAdd}${classBigAdd}`}>{buttonCreator(combatChapters, tooSmall, setChapterSelected, tooBig, classBigAdd)}</div>
                </div>
                <div style={{overflowY: 'visible'}}>
                    <div className={`chaptertag${classBigAdd}`}>COMBAT (SERVICE) SUPPORT</div><br/>
                    <div className={`flexbox${classAdd}${classBigAdd}`}>{buttonCreator(cssChapters, tooSmall, setChapterSelected, tooBig, classBigAdd)}</div>
                </div>
                <div style={{overflowY: 'visible'}}>
                    <div className={`chaptertag${classBigAdd}`}>SPECIAL USE CASE</div><br/>
                    <div className={`flexbox${classAdd}${classBigAdd}`}>{buttonCreator(miscChapters, tooSmall, setChapterSelected, tooBig, classBigAdd)}</div>
                </div>
            </div>
            }
            {chapterSelected &&
                <div style={{position: "relative"}}>
                    <div>
                        <p className={`selection${classBigAdd}`}>Make your Selection</p>
                        {!tooSmall && 
                        <p className={`note${classBigAdd}`}>Note: some options are only available based on previous selections</p>
                        }
                    </div>
                <div className={`dropcontainer${classAdd}`}>
                    <div className={`dropdown${classAdd}`}>
                        <Select
                            id={'weaponSelection'}
                            placeholder="Select a Weapon..."
                            isClearable
                            options={weaponOptions}
                            value={weaponValue}
                            onChange={(newValue) => {setWeaponValue(newValue), setCatValue(null), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    ...tooBigStyle,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                }),
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        ...tooBigStyle,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                    };
                                },
                            }}
                            />
                    </div>
                    <div className={`dropdown${classAdd}`}>
                        <Select
                            id={'eventTypeSelection'}
                            placeholder="Select a Event Type..."
                            isClearable
                            options={eventsOptions}
                            value={eventsValue}
                            onChange={(newValue) => {setEventsValue(newValue), setCatValue(null), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    ...tooBigStyle,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                }),
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        ...tooBigStyle,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                    };
                                },
                            }}/>
                    </div>
                    <div className={`dropdown${classAdd}`}>
                        <Select
                            id={'catSelection'}
                            placeholder="Select a Category..."
                            isClearable
                            options={catOptions}
                            value={catValue}
                            onChange={(newValue) => {setCatValue(newValue), setEveValue([])}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            blurInputOnSelect={true}
                            styles = {{
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    ...tooBigStyle,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                }),
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        ...tooBigStyle,
                                        backgroundColor: isDisabled
                                            ? undefined
                                            : isSelected
                                            ? '#bababa'
                                            : isFocused
                                            ? '#f5f5f5'
                                            : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                    
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                    };
                                },
                            }}/>
                    </div>
                    <div className={`dropdown${classAdd}`}>
                        <Select 
                            id={'trainingSelection'}
                            components={{ Option }}
                            placeholder={givePlaceholder(isEventsMulti)}
                            isClearable
                            options={eveOptions}
                            value={eveValue}
                            onChange={(newValue) => {setEveValue(newValue)}}
                            noOptionsMessage={() => null}
                            isValidNewOption={() => false}
                            isDisabled = {Boolean(!catValue)}
                            isMulti
                            controlShouldRenderValue={!isEventsMulti}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles = {{
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    ...tooBigStyle,
                                    borderColor: state.isFocused ? 'gold' : 'grey',
                                    boxShadow: state.isFocused ? '0 0 0px 1px gold' : 'none',
                                    '&:hover': {
                                        borderColor: 'gold'
                                    }
                                }),
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                option: (styles, { isDisabled, isFocused, isSelected }) => {
                                    return {
                                        ...styles,
                                        ...tooBigStyle,
                                        backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#bababa'
                                        : isFocused
                                        ? '#f5f5f5'
                                        : undefined,
                                        color: 'black',
                                        cursor: isDisabled ? 'not-allowed' : 'default',
                                        
                                        ':active': {
                                            ...styles[':active'],
                                            backgroundColor: '#bababa'
                                        },
                                    };
                                },
                                //DO NOT DELETE THIS COMMENT!!!!!
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                placeholder: defaultStyles => {
                                    return {
                                    ...defaultStyles,
                                    color: isEventsMulti ? '#333333' : '#808080'
                                    };
                                }
                            }}
                            />
                    </div>
                    <div className="iterator" >
                        <div className={`iteratorLabel${classBigAdd}`} >Weapon Count</div>
                        <Iterator onChange={setweaponCount} currValue={weaponCount} tooBig={tooBig} />
                        <div className={`iteratorLabel${classBigAdd}`} >Iteration Count</div>
                        <Iterator onChange={setIterationCount} currValue={iterationCount} tooBig={tooBig} />
                    </div>
                </div>
                {/* if cart is empty display */}
                {!(eveValue.length) &&
                    <div className={`calccontainer${classAdd}${classBigAdd}`}>
                        Results
                            <div className={`resultcontainer${classBigAdd}`}>
                                <div className={`resultinnercontainer${classBigAdd}`}>
                                    <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5", ...tooBigCalcTableStyle}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: "bold", ...tooBigCalcTableStyle }}>QTY</TableCell>
                                                    <TableCell sx={{fontWeight: "bold", ...tooBigCalcTableStyle }}>Ammunition</TableCell>
                                                    <TableCell sx={{fontWeight: "bold", ...tooBigCalcTableStyle }}>DODIC</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ"}</TableCell>
                                                    <TableCell sx={{cursor: "default"}}>{"ㅤㅤㅤ"}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        <div className={`calcbtncontainer${classBigAdd}`}>
                            <Button sx={{...tooBigCalcButtonStyle, backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                marginLeft: '1vw', color: 'black', borderRadius: "5px"}}
                                onClick={() => {
                                    setWeaponValue(null); setEventsValue(null); setCatValue(null); setEveValue([]); 
                                    setweaponCount(1); setIterationCount(1)}}>ADD TO CART
                            </Button>
                        </div>
                    </div>
                }
                {/* if items in cart display */}
                {(eveValue.length > 0) &&
                    <div className={`calccontainer${classAdd}${classBigAdd}`}>
                        Results
                        <div className={`resultcontainer${classBigAdd}`}>
                            <div className={`resultinnercontainer${classBigAdd}`}>
                                <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5"}}>
                                    <Table>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "bold", ...tooBigCalcTableStyle }}>QTY</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", ...tooBigCalcTableStyle  }}>Ammunition</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", ...tooBigCalcTableStyle  }}>DODIC</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {calculate(tooBig, is10Added, chapterSelected, eveValue, catValue, weaponCount, iterationCount, currentCalcs)}  
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className={`calcbtncontainer${classBigAdd}`}>
                            <Button sx={{
                                backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                marginLeft: '1vw', color: 'black', borderRadius: "5px", height:'fit-content', ...tooBigCalcButtonStyle}}
                                onClick={() => {
                                    setSavedCalcs([...savedCalcs, currentCalcs[0]]); 
                                    titleMaker(chapterSelected, catValue, weaponCount, iterationCount, eveValue, savedTitles, 
                                        setSavedTitles, tooBig); setWeaponValue(null); setEventsValue(null); setCatValue(null);
                                    setEveValue([]); setweaponCount(1); setIterationCount(1);
                                } }>ADD TO CART
                            </Button>
                        </div>
                    </div>
                }
                <div className={`quickbox${classAdd}`}>
                    <div className={`quickLinksHeader${classBigAdd}`} >Quick Requistion Links</div>
                    {quickLinkMaker(tooBig, {label: 'M4', value: 'M4'}, {label: 'Qualification', value: 'Qualification'},
                        {label: 'M16/M4 Qualification', value: 'M16/M4 Qualification'}, [{label: 'Zero/Group/KD', value: 'Zero/Group/KD'},
                        {label: 'Practice', value: 'Practice'}, {label: 'Day Qualification', value: 'Day Qualification'}], 'M4 Qualification',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, {label: 'M4', value: 'M4'}, {label: 'Collective Training', value: 'Collective Training'},
                        {label: 'M16/M4 Collective Training', value: 'M16/M4 Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                        {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M4 Collective Training',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, {label: 'M240', value: 'M240'}, {label: 'Qualification', value: 'Qualification'},
                        {label: 'M240 Qualification', value: 'M240 Qualification'}, [{label: '10m Zero', value: '10m Zero'},
                        {label: '10m Practice', value: '10m Practice'}, {label: '10m Record', value: '10m Record'}], 'M240 Qualification',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, {label: 'M240', value: 'M240'}, {label: 'Collective Training', value: 'Collective Training'},
                        {label: 'M240 MG Collective Training', value: 'M240 MG Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                        {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M240 Collective Training',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, {label: 'M249', value: 'M249'}, {label: 'Qualification', value: 'Qualification'},
                        {label: 'M249 Qualification', value: 'M249 Qualification'}, [{label: '10m Zero', value: '10m Zero'},
                        {label: '10m Practice', value: '10m Practice'}, {label: '10m Record', value: '10m Record'}], 'M249 Qualification',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, {label: 'M249', value: 'M249'}, {label: 'Collective Training', value: 'Collective Training'},
                        {label: 'M249 Collective Training', value: 'M249 Collective Training'}, [{label: 'Sqd/Plt LFX', value: 'Sqd/Plt LFX'},
                        {label: 'Sqd/Plt/Co LFX (Blank)', value: 'Sqd/Plt/Co LFX (Blank)'}], 'M249 Collective Training',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JRTC', value: 'JRTC'}, {label: 'JRTC IBCT Force On Force', value: 'JRTC IBCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'JRTC IBCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JRTC', value: 'JRTC'}, {label: 'JRTC IBCT Live Fire', value: 'JRTC IBCT Live Fire'}, 
                        [{label: 'Live Fire', value: 'Live Fire'}], 'JRTC IBCT Live Fire',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'NTC', value: 'NTC'}, {label: 'NTC ABCT Force On Force', value: 'NTC ABCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'NTC ABCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'NTC', value: 'NTC'}, {label: 'NTC ABCT Live Fire', value: 'NTC ABCT Live Fire'}, 
                        [{label: 'Live Fire', value: 'Live Fire'}], 'NTC ABCT Live Fire',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'NTC', value: 'NTC'}, {label: 'NTC SBCT Force On Force', value: 'NTC SBCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'NTC SBCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'NTC', value: 'NTC'}, {label: 'NTC SBCT Live Fire', value: 'NTC SBCT Live Fire'}, 
                        [{label: 'Live Fire', value: 'Live Fire'}], 'NTC SBCT Live Fire',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC IBCT Force On Force', value: 'JMRC IBCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC IBCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC IBCT Live Fire', value: 'JMRC IBCT Live Fire'}, 
                        [{label: 'Live Fire', value: 'Live Fire'}], 'JMRC IBCT Live Fire',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC ABCT Force On Force', value: 'JMRC ABCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC ABCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC ABCT Live Fire', value: 'JMRC ABCT Live Fire'}, 
                        [{label: 'Live Fire', value: 'Live Fire'}], 'JMRC ABCT Live Fire',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                    {quickLinkMaker(tooBig, null, {label: 'JMRC', value: 'JMRC'}, {label: 'JMRC SBCT Force On Force', value: 'JMRC SBCT Force On Force'}, 
                        [{label: 'Force on Force', value: 'Force on Force'}], 'JMRC SBCT Force on Force',
                        weaponOptions, eventsOptions, catOptions, setTempWeaponValue, setTempEventsValue, setTempCatValue, setTempEveValue, setQuickSetClicked)}
                </div>
                {(savedCalcs.length > 0) &&
                <>
                    {tooSmall && 
                    <div className="mobilecart">
                        <>{MobileCart(props)}</>
                        <div className="cartbtndivsmall">
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {setSavedCalcs([]); setSavedTitles([])}}>Clear</Button>
                            <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {modifyPdf(savedCalcs)}}><FileUploadOutlinedIcon/>Export</Button>
                        </div>
                    </div>
                    }
                </>
                }
            </div>
            }
        </div>
    )
}

export default MainContent;
