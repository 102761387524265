import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import '../../styles/iterator.css'

interface IIteratorProps {
    onChange: (value: number) => void
    currValue: number
    tooBig: boolean
}

function Iterator(props: IIteratorProps): React.ReactElement {    
    let iteratorBiggenator = ""
    let addRemoveIconStyle : any = {
        cursor: 'pointer',
        fontSize: '14px'
    }
    if (props.tooBig) {
        iteratorBiggenator = "big"
        addRemoveIconStyle = {
            cursor: 'pointer',
            fontSize: '30px',
            marginLeft: '20px'
        }
    }
    
    return (
        <div style={{ display: 'flex', margin: 'auto', alignItems: 'center', fontSize: '16px', cursor: 'default' }}>
            {/* this button -5 from current count */}
            <span className={`iterator5${iteratorBiggenator}`} title='minusFive' onClick={() => {
                        if (props.currValue > 5) props.onChange(props.currValue - 5)
                        if (props.currValue <= 5) props.onChange((props.currValue+1) - props.currValue)
                    }}>
                {/* minus icon if >5 -5 if <=5 make count 1 */}
                <div className={`minusIcon${iteratorBiggenator}`}>
                    <RemoveIcon style={addRemoveIconStyle} onClick={() => {
                            if (props.currValue > 5) props.onChange(props.currValue - 5)
                            if (props.currValue <= 5) props.onChange((props.currValue+1) - props.currValue)
                        }}>
                    </RemoveIcon>
                </div>
            5</span>
            {/* gap between -5 and -1 */}
            <span style={{marginRight: '10px'}}></span>
            {/* this button -1 from current count */}
            <span className={`iterator1${iteratorBiggenator}`} title='minusOne' onClick={() => {
                        if (props.currValue > 1) props.onChange(props.currValue - 1)
                    }}>
                <div className={`minusIcon${iteratorBiggenator}`}>
                    <RemoveIcon style={{...addRemoveIconStyle , marginLeft: '1.5px' }} onClick={() => {
                            if (props.currValue > 1) props.onChange(props.currValue - 1)
                        }}>
                    </RemoveIcon>
                </div>
            </span>
            {/* current count */}
            <span className={`value${iteratorBiggenator}`} title={`currentValue${props.currValue}`} style={{marginRight: '4px', marginLeft: '12px', marginTop: '6px', alignContent: 'center'}}>
                {props.currValue}
            </span>
            {/* gap between current count and +1 */}
            <span style={{marginLeft: '10px'}}></span>
            {/* this button +1 from current count */}
            <span className={`iterator1${iteratorBiggenator}`} title='plusOne' onClick={() => {props.onChange(props.currValue + 1)}}>
                <div className={`addIcon${iteratorBiggenator}`}>
                    <AddIcon style={{...addRemoveIconStyle, marginLeft: '1.5px' }} onClick={() => {props.onChange(props.currValue + 1)}}></AddIcon>
                </div>
            </span>
            {/* gap between +1 and +5 */}
            <span style={{marginLeft: '10px'}}></span>
            {/* this button +5 from current count */}
            <span className={`iterator5${iteratorBiggenator}`} title='plusFive' onClick={() => {props.onChange(props.currValue + 5)}}>
                <div className={`addIcon${iteratorBiggenator}`}>
                    <AddIcon style={addRemoveIconStyle} onClick={() => {props.onChange(props.currValue + 5)}}></AddIcon>
                </div>
            5</span>
        </div>
    )
}

export default Iterator