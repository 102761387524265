import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Button } from '@mui/material';
import { Option } from '../../interfaces';
import '../../styles/description.css'

export default function Description( props : any ) {
    const catValue : Option | null = props.catValue
    const chapterSelected : string | undefined = props.chapterSelected
    const eveValue : readonly Option[] = props.eveValue
    const eventsValue : Option | null = props.eventsValue
    const isModalOpen : boolean = props.isModalOpen
    const setModalOpen : React.Dispatch<React.SetStateAction<boolean>> = props.setModalOpen
    const tooSmall : boolean = props.tooSmall
    const tooBig : boolean = props.tooBig
    const classBigAdd : string = props.classBigAdd
    const weaponValue : Option | null = props.weaponValue

    let step1 = false;
    let step2 = false;
    let step3 = false;
    let step4 = false;
    if(eveValue.length > 0){step1 = true; step2 = true; step3 = true; step4 = true;}
        else if(catValue){step1 = true; step2 = true; step3 = true;}
        else if(eventsValue){step1 = true; step2= true;}
        else if(weaponValue){step1 = true;}
    return(
        <><>{!tooSmall && !tooBig &&
            <div className="description">
                {chapterSelected &&
                    <><div className="descriptionInnerBox">
                        <h4 style={{ marginTop: "0px" }}>Steps</h4><br />
                        <div style={{ fontWeight: "bold" }}>*<span style={{ color: "red" }}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br />
                        <ul style={{ textAlign: "left" }}>
                            {step1 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'medium' }} />Select a Weapon Type<br /><br /></>}
                            {!step1 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'medium' }} />Select a Weapon Type<br /><br /></>}
                            {step2 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'medium' }} />Select an Event Type<br /><br /></>}
                            {!step2 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'medium' }} />Select an Event Type<br /><br /></>}
                            {step3 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'medium' }} />Select a Category<br /><br /></>}
                            {!step3 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'medium' }} />Select a Category<br /><br /></>}
                            {step4 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'medium' }} />Select all Training Exercises<br /><br /><br /></>}
                            {!step4 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'medium' }} />Select all Training Exercises<br /><br /><br /></>}
                            <li>Add Weapon Count</li><br />
                            <li>Add Number of Iterations</li><br />
                            <li>Click "Add to Cart"</li><br />
                            <li>Repeat as Needed</li>
                        </ul>
                        <br />
                        <div className="linkdiv" style={{ position: "absolute", width: '100%' }}>
                            <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                target="_blank">CAPULDI 2009</a><br />
                            <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                target="_blank">CAPULDI 2023 EXCEL</a><br />
                            <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                target="_blank">YELLOW BOOK</a>
                        </div>
                    </div>
                        <div className="descriptionButtonDiv">
                            <Button sx={{
                                backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '10px', left: '0px', color: 'white', borderRadius: "5px"
                            }}
                                onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us</Button>
                        </div></>}
                {!chapterSelected &&
                    <><div className="descriptionInnerBox">
                        <h4 style={{ marginTop: "0px" }}>What is AmmoOp?</h4><br />
                        <div style={{ fontWeight: "bold" }}>*<span style={{ color: "red" }}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br />
                        <div>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically
                            calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates,
                            we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the
                            current version, saving you both time and reducing stress. Enjoy using AmmoOp!</div>
                        <br />
                        <div className="linkdiv" style={{ position: "absolute", width: '100%' }}>
                            <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                target="_blank">CAPULDI 2009</a><br />
                            <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                target="_blank">CAPULDI 2023 EXCEL</a><br />
                            <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                target="_blank">YELLOW BOOK</a>
                        </div>
                    </div>
                        <div className="descriptionButtonDiv">
                            <Button sx={{
                                backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: '32px', marginLeft: '10px', left: '0px', color: 'white', borderRadius: "5px"
                            }}
                                onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us</Button>
                        </div></>}
            </div>}</>

            <>{tooBig &&
            <div className={`description${classBigAdd}`}>
                {chapterSelected &&
                    <><div className={`descriptionInnerBox${classBigAdd}`}>
                        <h4 style={{ marginTop: "0px" }}>Steps</h4><br />
                        <div style={{ fontWeight: "bold" }}>*<span style={{ color: "red" }}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br />
                        <ul style={{ textAlign: "left" }}>
                            {step1 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select a Weapon Type<br /><br /></>}
                            {!step1 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select a Weapon Type<br /><br /></>}
                            {step2 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select an Event Type<br /><br /></>}
                            {!step2 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select an Event Type<br /><br /></>}
                            {step3 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select a Category<br /><br /></>}
                            {!step3 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select a Category<br /><br /></>}
                            {step4 &&
                                <><CheckBoxOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select all Training Exercises<br /><br /><br /></>}
                            {!step4 &&
                                <><CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: 'xx-large' }} />Select all Training Exercises<br /><br /><br /></>}
                            <li>Add Weapon Count</li><br />
                            <li>Add Number of Iterations</li><br />
                            <li>Click "Add to Cart"</li><br />
                            <li>Repeat as Needed</li>
                        </ul>
                        <br />
                        <div className="linkdiv" style={{ position: "absolute", width: '100%' }}>
                            <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                target="_blank">CAPULDI 2009</a><br />
                            <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                target="_blank">CAPULDI 2023 EXCEL</a><br />
                            <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                target="_blank">YELLOW BOOK</a>
                        </div>
                    </div>
                        <div className={`descriptionButtonDiv${classBigAdd}`}>
                            <Button sx={{
                                backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: 'fit-content', width: 'fit-content', marginLeft: '0.26vw', left: '0px', color: 'white', borderRadius: "5px", fontSize: '1.25vw'}}
                                onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us</Button>
                        </div>
                    </>
                }
                {!chapterSelected &&
                    <><div className={`descriptionInnerBox${classBigAdd}`}>
                        <h4 style={{ marginTop: "0px" }}>What is AmmoOp?</h4><br />
                        <div style={{ fontWeight: "bold" }}>*<span style={{ color: "red" }}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an
                            email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*</div><br />
                        <div>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically
                            calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates,
                            we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the
                            current version, saving you both time and reducing stress. Enjoy using AmmoOp!</div>
                        <br />
                        <div className="linkdiv" style={{ position: "absolute", width: '100%' }}>
                            <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                target="_blank">CAPULDI 2009</a><br />
                            <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                target="_blank">CAPULDI 2023 EXCEL</a><br />
                            <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                target="_blank">YELLOW BOOK</a>
                        </div>
                    </div>
                        <div className={`descriptionButtonDiv${classBigAdd}`}>
                            <Button sx={{
                                backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                height: 'fit-content', width: 'fit-content', marginLeft: '0.26vw', left: '0px', color: 'white', borderRadius: "5px", fontSize: '1.25vw'}}
                                onClick={() => { setModalOpen(!isModalOpen); } }>Contact Us</Button>
                        </div>
                    </>
                }
            </div>}
        </></>
    )
}