import { PDFForm, PDFFont, PDFPage, TextAlignment, PDFTextField } from 'pdf-lib';
import { MAX_PAGES } from '../../constants';

function createTextBox(form: PDFForm, font:PDFFont, pages: PDFPage[], page: number, id: string,
                       filler: string, alignment: TextAlignment, x: number, y: number, width: number, height: number,
                       fontSize: number = height/2.5, isTall = false){
    const textBox = form.createTextField(id);
    textBox.setAlignment(alignment)
    textBox.addToPage(pages[page], {
        x: x,
        y: y,
        width: width,
        height: height,
        font: font
    })
    if (isTall) textBox.enableMultiline();
    textBox.setText(filler);
    textBox.setFontSize(fontSize);
    form.getTextField(id).setText('');
}

function createCheckbox(form: PDFForm, pages: PDFPage[], page: number, id: string, x: number, y: number, width: number, height: number){
    const checkBox = form.createCheckBox(id);
    checkBox.addToPage(pages[page], {
        x: x,
        y: y,
        width: width,
        height: height
    });
}

export function createNonFunctionalFields(form: PDFForm, font: PDFFont, pages: PDFPage[], cursive: PDFFont): void{
    const rows = [424, 400.5, 377, 353.5, 330, 306, 282];
    const rowHeight = 22.5;

    for (let i = 0; i < 7; i ++){
        createTextBox(form, font, pages, 0, ('ui.'+(i+1).toString()), "##", TextAlignment.Center, 292, rows[i], 22.5, rowHeight);
        createTextBox(form, font, pages, 0, ('tec.'+(i+1).toString()), "AAAA", TextAlignment.Center, 371.5, rows[i], 30, rowHeight);
        createTextBox(form, font, pages, 0, ('action.code.'+(i+1).toString()), "AA", TextAlignment.Center, 403, rows[i], 29.5, rowHeight);
        createTextBox(form, font, pages, 0, ('amount.issued.'+(i+1).toString()), "####", TextAlignment.Center, 434, rows[i], 61.5, rowHeight);
        createTextBox(form, font, pages, 0, ('lot.'+(i+1).toString()), "#########-###", TextAlignment.Center, 497, rows[i], 98, rowHeight);
        createTextBox(form, font, pages, 0, ('cc.'+(i+1).toString()), "AA", TextAlignment.Center, 596.5, rows[i], 22, rowHeight);
        createTextBox(form, font, pages, 0, ('posted.by.'+(i+1).toString()), "Alphabet Soup", TextAlignment.Center, 620, rows[i], 84.5, rowHeight);
        createTextBox(form, font, pages, 0, ('date.'+(i+1).toString()), "YYYYMMDD", TextAlignment.Center, 706.5, rows[i], 56.5, rowHeight);
    }

    createCheckbox(form, pages, 0, '1.issue', 363.8, 567.25, 10.5, 10.5);
    createCheckbox(form, pages, 0, '2.turn.in', 363.8, 551.5, 10.5, 10.5);
    createTextBox(form, font, pages, 0, '3.document.no', '####', TextAlignment.Center, 378.5, 550, 122, 22.5);
    createTextBox(form, font, pages, 0, '4.local.use', 'AAAA', TextAlignment.Center, 504.5, 550, 54.5, 22.5);
    createTextBox(form, font, pages, 0, '6.for.local.use', 'AAAAAAAA', TextAlignment.Center, 620, 550, 140, 22.5);
    createTextBox(form, font, pages, 0, '7.send.to', 'A Big Old Unit Rd, City, State #####', TextAlignment.Left, 32, 478.5, 140.5, 62, 11, true);
    createTextBox(form, font, pages, 0, '8.request.from', 'An Ammo Point, City, State #####', TextAlignment.Left, 174, 478.5, 140.5, 62, 11, true);
    createTextBox(form, font, pages, 0, '9.date.material,required', 'YYYYMMDD', TextAlignment.Center, 317, 526.5, 177.5, 12);
    createTextBox(form, font, pages, 0, '10.priority', 'URGENT', TextAlignment.Center, 497.5, 526.5, 52.5, 12);
    createTextBox(form, font, pages, 0, '11.allocation.period', '9000 Years', TextAlignment.Center, 555, 526.5, 95, 12);
    createTextBox(form, font, pages, 0, '12.dodacc', '####-##-###-####', TextAlignment.Center, 655, 526.5, 106.5, 12);
    createTextBox(form, font, pages, 0, '13.a.requested.by', 'SFC Norman Reedus', TextAlignment.Center, 317, 502, 177.5, 12);
    createTextBox(form, font, pages, 0, '13.b.date', 'YYYYMMDD', TextAlignment.Center, 498, 502, 82.75, 12);
    createTextBox(form, cursive, pages, 0, '13.c.signature', 'Norman Reedus', TextAlignment.Center, 584.5, 502, 176.5, 12, 6); //Cursive
    createTextBox(form, font, pages, 0, '14.a.approved.by', 'PFC Joe Snuffy', TextAlignment.Center, 317, 479.5, 177.5, 12);
    createTextBox(form, font, pages, 0, '14.b.date', 'YYYYMMDD', TextAlignment.Center, 498, 479.5, 82.75, 12);
    createTextBox(form, cursive, pages, 0, '14.c.signature', 'Joe Snuffy', TextAlignment.Center, 584.5, 479.5, 176.5, 12, 6); //Cursive
    createTextBox(form, font, pages, 0, '28.remarks', 'A massive amount of text can go here! Like, just, so, so, so much text',
                  TextAlignment.Left, 32, 105, 563, 162, 14, true);
    createTextBox(form, font, pages, 0, '29.related.serials', '#########-###', TextAlignment.Left, 596.5, 105, 166.5, 162, 14, true);
    createTextBox(form, font, pages, 0, '30.a.issued.by', 'PFC Joe Snuffy', TextAlignment.Center, 32, 77, 250.45, 15.5);
    createTextBox(form, cursive, pages, 0, '30.b.signature', 'Joe Snuffy', TextAlignment.Center, 32, 52, 250.45, 15.5, 6.8); //Cursive
    createTextBox(form, font, pages, 0, '30.c.date', 'YYYYMMDD', TextAlignment.Center, 283.95, 52, 60, 31, 10);
    createTextBox(form, font, pages, 0, '31.a.issued.by', 'SFC Norman Reedus', TextAlignment.Center, 345.45, 77, 250, 15.5);
    createTextBox(form, cursive, pages, 0, '31.b.signature', 'Norman Reedus', TextAlignment.Center, 345.45, 52, 250, 15.5, 6.8); //Cursive
    createTextBox(form, font, pages, 0, '31.c.date', 'YYYYMMDD', TextAlignment.Center, 596.9, 52, 61.15, 31, 10);
    createTextBox(form, font, pages, 0, '32.tamis.control.no', '####', TextAlignment.Center, 662, 52, 98.5, 38.25, 14);

    const signatureBlocks: PDFTextField[] = [form.getTextField('13.c.signature'),form.getTextField('14.c.signature'),
                                             form.getTextField('30.b.signature'), form.getTextField('31.b.signature')];
    for (let i = 0; i < signatureBlocks.length; i++){
        signatureBlocks[i].updateAppearances(cursive);
    }
}

export function createFunctionalTextFields(form: PDFForm, font: PDFFont, pages: PDFPage[]): void {
    const rows = [424, 400.5, 377, 353.5, 330, 306, 282];
    const textHeight = 22.5;

    for (let i = 0; i < 7; i ++){
        createTextBox(form, font, pages, 0, ('item.'+(i+1).toString()), (i+1).toString(), TextAlignment.Center, 32, rows[i], 23, textHeight);
        createTextBox(form, font, pages, 0, ('dodic.'+(i+1).toString()), "00000", TextAlignment.Center, 56.5, rows[i], 30, textHeight);
        createTextBox(form, font, pages, 0, ('nsn.'+(i+1).toString()), "0000-00-000-0000", TextAlignment.Center, 88, rows[i], 108, textHeight);
        createTextBox(form, font, pages, 0, ('name.'+(i+1).toString()), "CTG, FAKEMM, BALL, M0000A1, 15 RD CLIP/BANDOLEERS (3000/ MAGIC BX)",
                                             TextAlignment.Left, 197.5, rows[i], 93, textHeight, 5, true);
        createTextBox(form, font, pages, 0, ('amount.'+(i+1).toString()), "1000", TextAlignment.Center, 316, rows[i], 54, textHeight);
    }

    createTextBox(form, font, pages, 0, '5.page.left', '##', TextAlignment.Center, 562, 550, 20, textHeight);
    createTextBox(form, font, pages, 0, '5.page.right', '##', TextAlignment.Center, 595.4, 550, 20, textHeight);
}

export function createSecondaryPagesTextFields(form: PDFForm, font: PDFFont, pages: PDFPage[], dodicsTotal: number): void{
    const rows =[494.5, 471, 447.5, 424, 400.5, 376.7, 353.2, 329.6, 305.8, 282, 258.8, 235, 211.5, 188, 164.1, 140.8, 116.75, 93, 69.5, 46];
    const textHeight = 22.5;
    const pagesToGo = Math.ceil((dodicsTotal-7)/20);
    for (let p = 0; p < pagesToGo && p < MAX_PAGES; p++){
        for (let i = 0; i < rows.length; i ++){
            //Functional
            createTextBox(form, font, pages, (p+1), ('item.'+(i+8+(p*20)).toString()), (i+8+(p*20)).toString(), TextAlignment.Center, 32, rows[i], 23, textHeight);
            createTextBox(form, font, pages, (p+1), ('dodic.'+(i+8+(p*20)).toString()), "00000", TextAlignment.Center, 56.5, rows[i], 30, textHeight);
            createTextBox(form, font, pages, (p+1), ('nsn.'+(i+8+(p*20)).toString()), "0000-00-000-0000", TextAlignment.Center, 88, rows[i], 108, textHeight);
            createTextBox(form, font, pages, (p+1), ('name.'+(i+8+(p*20)).toString()), "CTG, FAKEMM, BALL, M0000A1, 15 RD CLIP/BANDOLEERS (3000/ MAGIC BX)",
                                                  TextAlignment.Left, 197.5, rows[i], 93, textHeight, 5, true);
            createTextBox(form, font, pages, (p+1), ('amount.'+(i+8+(p*20)).toString()), "1000", TextAlignment.Center, 316, rows[i], 54, textHeight);
            //Nonfunctional
            createTextBox(form, font, pages, (p+1), ('ui.'+(i+8+(p*20)).toString()), "##", TextAlignment.Center, 292, rows[i], 22.5, textHeight);
            createTextBox(form, font, pages, (p+1), ('tec.'+(i+8+(p*20)).toString()), "AAAA", TextAlignment.Center, 371.5, rows[i], 30, textHeight);
            createTextBox(form, font, pages, (p+1), ('action.code.'+(i+8+(p*20)).toString()), "AA", TextAlignment.Center, 403, rows[i], 29.5, textHeight);
            createTextBox(form, font, pages, (p+1), ('amount.issued.'+(i+8+(p*20)).toString()), "####", TextAlignment.Center, 434, rows[i], 61.5, textHeight);
            createTextBox(form, font, pages, (p+1), ('lot.'+(i+8+(p*20)).toString()), "#########-###", TextAlignment.Center, 497, rows[i], 98.5, textHeight);
            createTextBox(form, font, pages, (p+1), ('cc.'+(i+8+(p*20)).toString()), "AA", TextAlignment.Center, 596.5, rows[i], 22, textHeight);
            createTextBox(form, font, pages, (p+1), ('posted.by.'+(i+8+(p*20)).toString()), "Alphabet Soup", TextAlignment.Center, 620, rows[i], 86.5, textHeight);
            createTextBox(form, font, pages, (p+1), ('date.'+(i+8+(p*20)).toString()), "YYYYMMDD", TextAlignment.Center, 708.5, rows[i], 54.5, textHeight);
        }
        //Functional
        createTextBox(form, font, pages, (p+1), ('page.left.'+(p+1).toString()), '##', TextAlignment.Center, 563.18, 550.5, 20, 21.5);
        createTextBox(form, font, pages, (p+1), ('page.right.'+(p+1).toString()), '##', TextAlignment.Center, 595.76, 550.5, 20, 21.5);
        //Nonfunctional
        createCheckbox(form, pages, (p+1), ('issue.'+(p+1).toString()), 365.15, 567.5, 10.5, 10.5);
        createCheckbox(form, pages, (p+1), ('turn.in.'+(p+1).toString()), 365.15, 552, 10.5, 10.5);
        createTextBox(form, font, pages, (p+1), ('document.no.'+(p+1).toString()), '####', TextAlignment.Center, 379.5, 550.5, 122, 21.5);
        createTextBox(form, font, pages, (p+1), ('local.use.'+(p+1).toString()), 'AAAA', TextAlignment.Center, 505.5, 550.5, 54.5, 21.5);
        createTextBox(form, font, pages, (p+1), ('for.local.use.'+(p+1).toString()), 'AAAAAAAA', TextAlignment.Center, 620, 550.5, 140, 21.5);
}
}