import { animated, useSpring } from '@react-spring/web';
import '../../styles/mobileDrawer.css'


export default function MobileDrawer(
    tooSmall : boolean,
    isMobileDrawerShowing : boolean, 
    setIsMobileDrawerShowing : React.Dispatch<React.SetStateAction<boolean>>
) : React.ReactElement {
        
    const screenWidth = window.innerWidth;
    const containerWidth = screenWidth*0.8

    const springs = useSpring({
        x: isMobileDrawerShowing ? 0 : -containerWidth,
        zIndex: 9
    })
    const buttonSprings = useSpring({
        x: isMobileDrawerShowing ? containerWidth : 0,
        zIndex: 10
    })
    const handleClick = () => {
        setIsMobileDrawerShowing(isMobileDrawerShowing => !isMobileDrawerShowing)
    }
    const drawerButton = () => {
        if (isMobileDrawerShowing){
            return(
                <button className="mobileInfoDrawerButton" onClick={handleClick}><span>{"<INFO"}</span></button>
            )
        } else {
            return(
                <button className="mobileInfoDrawerButton" onClick={handleClick}><span>{"INFO>"}</span></button>
            )
        }
    }

    return(
        <>
            {tooSmall && 
                <>
                    <animated.div
                        className="mobileInfoDrawerContainer"
                        style={{
                            width: containerWidth,
                            ...springs,
                        }}
                    >
                        <div className="mobileInfoDrawerDescriptionContainer">
                            <div className="descriptionInnerBox">
                                <h4 style={{ marginTop: "0px" }}>What is AmmoOp?</h4>
                                <br />
                                <div style={{ fontWeight: "bold" }}>*<span style={{ color: "red" }}>This Project is in a TESTING PHASE. Any issues or bugs found should be detailed in an
                                    email to the project manager found at the "contact us" link at the bottom of the webpage.</span>*
                                </div>
                                <br />
                                <div>AmmoOp is designed to simplify the process of ordering ammunition for individuals of any rank or position. It automatically
                                    calculates all ammunition requirements based on the latest STRAC for the chosen event or series of events. In upcoming updates,
                                    we plan to introduce a feature that allows users to create custom live fire ranges. For now, we hope you find value in the
                                    current version, saving you both time and reducing stress. Enjoy using AmmoOp!
                                </div>
                                <br />
                                <div className="linkdiv" style={{ position: "absolute", width: '100%' }}>
                                    <a href="https://www.dau.edu/sites/default/files/Migrated/CopDocuments/CAPULDI%202009.pdf"
                                        target="_blank">CAPULDI 2009</a><br />
                                    <a href="https://www.dau.edu/sites/default/files/2023-11/CAPULDI%20excel%2030%20November%202023.xlsx"
                                        target="_blank">CAPULDI 2023 EXCEL</a><br />
                                    <a href="https://www.dau.edu/cop/ammo/page/AEResearchTools"
                                        target="_blank">YELLOW BOOK</a>
                                </div>
                            </div>
                        </div>
                    </animated.div>
                    <animated.div
                    style={{
                        width: 'fit-content',
                        ...buttonSprings,
                    }}
                    >
                        {drawerButton()}
                    </animated.div>
                </>
            }
        </>
    )   
}