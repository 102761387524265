import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TableContainer from '@mui/material/TableContainer';
import { Button } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ReactElement } from 'react';
import { IStrategy } from '../../../interfaces';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { IconButton, Collapse, Box } from '@mui/material';
import Table from '@mui/material/Table';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { ARMY_DODICS } from '../../../constants';
import Paper from '@mui/material/Paper';
import '../../../styles/cartBox.css'

export default function CartBox( props : any ) {
    const chapterSelected : string | undefined = props.chapterSelected
    const modifyPdf : (savedCalcs: {[charRep: string]: IStrategy[];}[]) => Promise<1 | undefined> = props.modifyPdf
    const open : boolean = props.open
    const savedCalcs : {[charRep: string]: IStrategy[];}[] = props.savedCalcs
    const savedTitles : ReactElement<any, string | React.JSXElementConstructor<any>>[] = props.savedTitles
    const setSavedCalcs : React.Dispatch<React.SetStateAction<{[charRep: string]: IStrategy[];}[]>> = props.setSavedCalcs
    const setSavedTitles : React.Dispatch<React.SetStateAction<ReactElement<any, string | React.JSXElementConstructor<any>>[]>> = props.setSavedTitles
    const setOpen : React.Dispatch<React.SetStateAction<boolean>> = props.setOpen
    const tooSmall : boolean = props.tooSmall
    const tooBig : boolean = props.tooBig
    const classBigAdd : string = props.classBigAdd
    let tooBigCartBtnsStyle, tooBigCartIconStyle= {}
    let tooBigCartTableStyle = {}
    let tooBigCartHeaderStyle = {}
    if (tooBig){
        tooBigCartIconStyle = {
            fontSize : '1.25vw',
        }
        tooBigCartTableStyle = {
            fontSize : '40px',
        }
        tooBigCartBtnsStyle = {
            fontSize : '1.25vw',
            backgroundColor: "#D49F00",
            height: 'fit-content',
            width: 'fit-content',
            marginLeft: '1vw',
            color: 'black',
            borderRadius: "8px"
        }
        tooBigCartHeaderStyle = {
            margin: '5px',
            wrapText: 'auto'
        }
    }

    function tableMaker(calcs: { [charRep : string]: IStrategy[] }) {
        const tables: ReactElement[] = [];
        for (const key in calcs){
            for (let x = 0; x < ARMY_DODICS.length; x++) {
                if (calcs[key][0].charRep === ARMY_DODICS[x].charRep){
                    let sum = 0;
                    for (let z = 1; z < calcs[key].length; z++){
                        sum += calcs[key][z].count;
                    }
                    tables.push(
                        <TableRow sx={{ maxWidth:'95%' }}>
                            <TableCell sx={{ padding:'0', ...tooBigCartTableStyle }} component="th" scope="row">{sum.toLocaleString()}</TableCell>
                            <TableCell sx={{ padding:'1', ...tooBigCartTableStyle }}>{ARMY_DODICS[x].ammoName}</TableCell>
                            <TableCell sx={{ padding:'1', ...tooBigCartTableStyle }} align="left">{ARMY_DODICS[x].charRep}</TableCell>
                        </TableRow>
                    )
                }
            }
        }
        return (tables);
    }

    const renderCartContents = () => {
        const elements: ReactElement[] = [];
        for (let i = 0; i < savedCalcs.length; i++){
            console.log(savedTitles[i])
            elements.push(
                <>
                <TableHead>
                    <TableRow sx={{ maxWidth:'100%'}}>
                        <TableCell sx={{ fontWeight: "bold", padding: '1', ...tooBigCartHeaderStyle}}>
                            <IconButton  onClick={() => setOpen(!open)} sx={{ zIndex: 10}}>
                                {open ? <KeyboardArrowUpOutlinedIcon sx={{...tooBigCartIconStyle}}/> : <KeyboardArrowDownOutlinedIcon sx={{...tooBigCartIconStyle}}/>}
                            </IconButton>
                            {savedTitles[i]}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableRow sx={{ maxWidth:'100%' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={open} sx={{ maxWidth: `100%`, ...tooBigCartIconStyle }} timeout="auto" unmountOnExit>
                            <Box sx={{ maxWidth: `100%` }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={tooBigCartTableStyle}>
                                            <TableCell sx={{ padding:'0', ...tooBigCartTableStyle  }}>QTY</TableCell>
                                            <TableCell sx={{ padding:'1', ...tooBigCartTableStyle  }}>Ammunition</TableCell>
                                            <TableCell sx={{ padding:'1', ...tooBigCartTableStyle  }} align="right">DODIC </TableCell>
                                            <TableCell sx={{ padding:'1' }}>
                                                <div style={{ display: 'flex', marginTop: '25%' }}>
                                                    {/* Potential edit button for each saved calc here */}
                                                    {/* <BorderColorOutlinedIcon sx={{ fontSize: 'medium', cursor: 'pointer', "&:hover, &.Mui-focusVisible": { color: "cornflowerblue" }}} /> */}
                                                    <ClearIcon sx={{ fontSize: 'large', cursor: 'pointer', ...tooBigCartIconStyle, "&:hover, &.Mui-focusVisible": { color: "red" }}} 
                                                        onClick={() => {
                                                            setSavedCalcs(savedCalcs.filter((item => item !== savedCalcs[i]))) 
                                                            if (savedTitles.length > 1) {
                                                                setSavedTitles(savedTitles.slice(0, i).concat(savedTitles.slice(i + 1, savedTitles.length)))
                                                            } else {
                                                                setSavedTitles([])
                                                            }
                                                        }}/>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {tableMaker(savedCalcs[i])}
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                </>
            )
        }
        return(elements)
    }
    
    return(
        <>
            {!tooSmall && !tooBig &&
                <>
                    {chapterSelected &&
                        <div className="cartbox">
                            <div className="innerCartBox">
                                <div style={{fontSize: 'large', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', maxWidth: '100%'}}>
                                    <ShoppingCartOutlinedIcon sx={{fontSize: 'medium', fontWeight: 'bold'}}/> Ammo Requisition Count</div><br/>
                                    <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5" }}>
                                        <Table sx={{backgroundColor: '#f5f5f5', maxWidth: '95%'}}>
                                            <>{renderCartContents()}</>
                                        </Table>
                                    </TableContainer>
                                <div className="cartbtndiv">
                                    <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                        height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {setSavedCalcs([]); setSavedTitles([])}}>Clear</Button>
                                    <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                        height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px"}} onClick={() => {modifyPdf(savedCalcs)}}><FileUploadOutlinedIcon/>Export</Button>
                                </div>
                            </div>    
                        </div>
                    }
                </>
            }
            {tooBig &&
                <>
                    {chapterSelected &&
                        <div className="cartbox">
                            <div className={`innerCartBox${classBigAdd}`}>
                                <div style={{fontSize: '55px', fontWeight: 'bold', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', maxWidth: '100%'}}>
                                    <ShoppingCartOutlinedIcon sx={{...tooBigCartIconStyle}}/> Ammo Requisition Count</div><br/>
                                    <TableContainer component={Paper} sx={{ zIndex: 3, backgroundColor: "#f5f5f5", ...tooBigCartTableStyle}}>
                                        <Table sx={{backgroundColor: '#f5f5f5', maxWidth: '100%', ...tooBigCartTableStyle}}>
                                            <>{renderCartContents()}</>
                                        </Table>
                                    </TableContainer>
                                <div className={`cartbtndiv${classBigAdd}`}>
                                    <Button sx={{"&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, ...tooBigCartBtnsStyle}} 
                                        onClick={() => {setSavedCalcs([]); setSavedTitles([])}}>Clear</Button>
                                    <Button sx={{backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" },
                                        height: '32px', marginLeft: '1vw', color: 'black', borderRadius: "5px", ...tooBigCartBtnsStyle}} onClick={() => {modifyPdf(savedCalcs)}}>
                                            <FileUploadOutlinedIcon sx={{fontSize: '1.25vw'}}/>Export
                                    </Button>
                                </div>
                            </div>    
                        </div>
                    }
                </>
            }
        </>
    )
}