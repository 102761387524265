import { ALL_CATEGORIES, ARMY_DODICS, MAX_PAGES } from "./constants";
import { Option, ICategory, IStrategy, IChapter} from './interfaces';
import { Button, TableRow, TableCell, IconButton, List, ListItem } from "@mui/material";
import { PDFForm, PDFPage, PDFDocument, StandardFonts } from 'pdf-lib';
import { createFunctionalTextFields, createNonFunctionalFields, createSecondaryPagesTextFields } from "./components/ammoOP/pdfConstruction";

import CompressIcon from '@mui/icons-material/Compress';
import fontKit from '@pdf-lib/fontkit';
import { ReactElement } from "react";

function createEventTagList(
    quickSetClicked : boolean, 
    setEventsValue: React.Dispatch<React.SetStateAction<Option | null>>, 
    weaponValue : Option | null, 
    chapterSelected : string | undefined, 
    catOptions : {
        value: string;
        label: string;
    }[]
): string[] {
    const allEventTags: string[] = [];
    if (!quickSetClicked) setEventsValue(null);
    if(!weaponValue)
    for ( let i = 0; i < ALL_CATEGORIES.length; i++){
        for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
            if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                    if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                        for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                            if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                        }
                        break;
                    }
                }
                break;
            }
        }
    }
    else
    for ( let i = 0; i < ALL_CATEGORIES.length; i++){
        for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
            if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                for ( let j = 0; j < catOptions.length; j++)
                    if (ALL_CATEGORIES[i].tags.includes(weaponValue.label))
                        for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                            if (ALL_CATEGORIES[i].tags[x] === "EventType"){
                                for (let y = x; y < ALL_CATEGORIES[i].tags.length; y++){
                                    if(ALL_CATEGORIES[i].tags[y] !== "Break" && ALL_CATEGORIES[i].tags[y] !== "EventType" && !allEventTags.includes(ALL_CATEGORIES[i].tags[y]))
                                        allEventTags.push(ALL_CATEGORIES[i].tags[y]);
                                }
                                break;
                            }
                        }
                break;
            }
        }
    }
    return(allEventTags);
}

function createEventList(
    catValue : Option | null, 
    chapterSelected : string | undefined
) {
    let category: ICategory = 
    {
        "name": "Dummy Category",
        "chapters":["DEBUG"],
        "events": [
            { "name": "You shouldn't see this", "strategies": [], "isSelected": false },
        ],
        "tags": ["DEBUG"]
    };
    for(let i = 0; i < ALL_CATEGORIES.length; i++)
        for(let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
            if (catValue?.label === ALL_CATEGORIES[i].name && ALL_CATEGORIES[i].chapters[x] === chapterSelected){ 
                category = ALL_CATEGORIES[i];
    }
    const eventOptions = [];
    for (let i = 0; i < category.events.length; i++) eventOptions.push({value: category.events[i].name, label: category.events[i].name});
    return eventOptions;
}

function createWeaponTagList(
    chapterSelected : string | undefined
): string[] {
    const allWeaponTags: string[] = [];
    for (let i = 0; i < ALL_CATEGORIES.length; i++){
        for ( let k = 0; k < ALL_CATEGORIES[i].chapters.length; k++){
            if (ALL_CATEGORIES[i].chapters[k] === chapterSelected){
                for (let x = 0; x < ALL_CATEGORIES[i].tags.length; x++){
                    if (ALL_CATEGORIES[i].tags[x] === "Break") break;
                    else if (ALL_CATEGORIES[i].tags[x] === "EventType") break;
                    else if (ALL_CATEGORIES[i].tags[x] !== "Weapon" && !ALL_CATEGORIES[i].tags[x].includes("Weapons") && !allWeaponTags.includes(ALL_CATEGORIES[i].tags[x]))
                        allWeaponTags.push(ALL_CATEGORIES[i].tags[x]);
                }
                break;
            }
        }
    }
    return(allWeaponTags);
}

function createWeaponsList(
    weaponTags : string[]
) {
    const weaponOptions = [];
    for (let i = 0; i < weaponTags.length; i++){
        weaponOptions.push({value: weaponTags[i], label: weaponTags[i]});
    }
    return weaponOptions;
}

function createEventTagsList(
    eventTags: string[]
) {
    const eventOptions = [];
    for (let i = 0; i < eventTags.length; i++){
        eventOptions.push({value: eventTags[i], label: eventTags[i]});
    }
    return eventOptions;
}

function createCategoriesList(
    weaponValue : Option | null, 
    eventsValue : Option | null,
    chapterSelected : string | undefined
) {
    const categoryOptions = [];
    for (let i = 0; i < ALL_CATEGORIES.length; i++){
        for (let x = 0; x < ALL_CATEGORIES[i].chapters.length; x++)
            if (ALL_CATEGORIES[i].chapters[x] === chapterSelected)
                if (weaponValue || eventsValue){
                    for (let y = 0; y < ALL_CATEGORIES[i].tags.length; y++){
                        if (weaponValue && !eventsValue){
                            if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, ''))){
                                categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                break;
                            }
                        }
                        if (eventsValue && !weaponValue){
                            if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                break;
                            }
                        }
                        if (eventsValue && weaponValue){
                            if (ALL_CATEGORIES[i].tags.includes(JSON.stringify(weaponValue.value).replace(/['"]+/g, '')) &&
                                ALL_CATEGORIES[i].tags.includes(JSON.stringify(eventsValue.value).replace(/['"]+/g, ''))){
                                categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                                break;
                            }
                        }
                    }
                }
                else {
                    categoryOptions.push({value: ALL_CATEGORIES[i].name, label: ALL_CATEGORIES[i].name})
                }
    }
    return categoryOptions;
}

function calculate(
    tooBig: boolean,
    is10: boolean,
    chapterSelected : string,
    eveValue : readonly Option[],
    catValue : Option | null,
    weaponCount : number,
    iterationCount : number,
    currentCalcs : {[charRep: string]: IStrategy[];}[],
) { 
    const Dodics: { [charRep : string]: IStrategy[] } = {};
    const elements: ReactElement[] = [];
    let tableCellStyle = {

    }
    if (tooBig){
        tableCellStyle = {
            fontSize : '40px'
        }
    }

    for (let i = 0; i < ALL_CATEGORIES.length; i++)
        if (chapterSelected) if (ALL_CATEGORIES[i].chapters.includes(chapterSelected) && ALL_CATEGORIES[i].name === catValue?.value)
            for (let x = 0; x < ALL_CATEGORIES[i].events.length; x++)
                for (let z = 0; z < eveValue.length; z++)
                    if (eveValue[z].label === ALL_CATEGORIES[i].events[x].name)
                        for (let y = 0; y < ALL_CATEGORIES[i].events[x].strategies.length; y++){
                            if (Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] === undefined){
                                Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep] = [];
                                Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push({charRep:ALL_CATEGORIES[i].events[x].strategies[y].charRep, count: 0 })
                            }
                            if(!is10)
                                Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push(
                                    {charRep: ALL_CATEGORIES[i].events[x].strategies[y].charRep,
                                     count: (Math.ceil(ALL_CATEGORIES[i].events[x].strategies[y].count*weaponCount*iterationCount))})
                            else
                                Dodics[ALL_CATEGORIES[i].events[x].strategies[y].charRep].push(
                                    {charRep: ALL_CATEGORIES[i].events[x].strategies[y].charRep,
                                     count: (Math.ceil(ALL_CATEGORIES[i].events[x].strategies[y].count*weaponCount*iterationCount*1.1))})
                        }
    for (const key in Dodics){
        for (let i = 0; i < ARMY_DODICS.length; i++)
            if (Dodics[key][0].charRep === ARMY_DODICS[i].charRep){
                let sum = 0;
                for (let x = 1; x < Dodics[key].length; x++)
                    sum += Dodics[key][x].count;
                if (sum || Dodics[key][0].count != 0){
                elements.push(
                    <TableRow>
                        <TableCell sx={tableCellStyle} >{(Math.ceil(sum)).toLocaleString()}</TableCell>
                        <TableCell sx={tableCellStyle} >{ARMY_DODICS[i].ammoName}</TableCell>
                        <TableCell sx={tableCellStyle} >{ARMY_DODICS[i].charRep}</TableCell>
                    </TableRow>
                )
                }
            }
    }
    currentCalcs.push(Dodics);
    return(elements)
}

function fillTextFields(
    form: PDFForm, 
    pages: PDFPage[], 
    exportableCalculations: IStrategy[]
): void {
    const dodicOrder: number[] = [];
    
    for (let i = 0; i < exportableCalculations.length; i++)
        for (let x = 0; x < ARMY_DODICS.length; x++)
            if (exportableCalculations[i].charRep === ARMY_DODICS[x].charRep)
                dodicOrder.push(x)

    for (let i = 0; i < exportableCalculations.length && i < (MAX_PAGES * 20 + 7); i++){
        if (exportableCalculations[i]){
            form.getTextField(('item.'+(i+1).toString())).setText(((i+1).toString()))
            form.getTextField(('dodic.'+(i+1).toString())).setText(exportableCalculations[i].charRep)
            form.getTextField(('nsn.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nsn)
            form.getTextField(('name.'+(i+1).toString())).setText(ARMY_DODICS[dodicOrder[i]].nomenclature)
            form.getTextField(('amount.'+(i+1).toString())).setText((exportableCalculations[i].count.toString()))
        }
    }
    form.getTextField('5.page.left').setText('1')
    form.getTextField('5.page.right').setText(pages.length.toString())
    for (let i = 1; i < pages.length; i++){
        form.getTextField(('page.left.'+(i).toString())).setText((i+1).toString())
        form.getTextField(('page.right.'+(i).toString())).setText(pages.length.toString())
    }
}

function quickLinkMaker(
    tooBig: boolean,
    weaponChoice: Option | null, 
    eventsChoice: Option | null, 
    catChoice: Option | null, 
    eveChoices: Option[], 
    linkName: string,
    weaponOptions : {value: string, label: string;}[],
    eventsOptions : {value: string, label: string;}[],
    catOptions : {value: string, label: string;}[],
    setTempWeaponValue : (value: React.SetStateAction<Option | null>) => void,
    setTempEventsValue : (value: React.SetStateAction<Option | null>) => void,
    setTempCatValue : (value: React.SetStateAction<Option | null>) => void,
    setTempEveValue : (value: React.SetStateAction<readonly Option[]>) => void,
    setQuickSetClicked : (value: React.SetStateAction<boolean>) => void
) {
    let weaponCheck = false;
    let eventsCheck = false;
    let catCheck = false;
    let quickLinkButtonStyle = {
        backgroundColor: "#bababa", "&:hover, &.Mui-focusVisible": { backgroundColor: "#bababa" },
        height: '2vh', width: 'fit-content', marginLeft: '1vw', color: 'black', borderRadius: "5px", fontSize: '12px',
        marginBottom: '1vw', float: 'left'
    }
    if (tooBig) {
        quickLinkButtonStyle = {
            backgroundColor: "#bababa", "&:hover, &.Mui-focusVisible": { backgroundColor: "#bababa" },
            height: '2vh', width: 'fit-content', marginLeft: '1vw', color: 'black', borderRadius: "5px", fontSize: '28px',
            marginBottom: '1vw', float: 'left'
        }
    }
    
    if (weaponChoice){ if(weaponOptions.some((x) => x.label === weaponChoice.label)) weaponCheck = true;} else weaponCheck = true;
    if (eventsChoice){ if(eventsOptions.some((x) => x.label === eventsChoice.label)) eventsCheck = true;} else eventsCheck = true;
    if (catChoice){ if(catOptions.some((x) => x.label === catChoice.label)) catCheck = true;} else catCheck = true;

    if (weaponCheck && eventsCheck && catCheck)
        return(
            <Button sx={quickLinkButtonStyle} onClick={() => {
                    setTempWeaponValue(weaponChoice)
                    setTempEventsValue(eventsChoice)
                    setTempCatValue(catChoice)
                    setTempEveValue(eveChoices)
                    setQuickSetClicked(true)
                }}>
            {linkName}</Button>
        )
    else return null;
}

async function modifyPdf(
    savedCalcs : {[charRep: string]: IStrategy[];}[]
) {
    const pdfData = await fetch('./DA581.pdf').then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });
    const calculatedTotalsExportable: IStrategy[] = [];
    const sortedCalcs: {[charRep: string]: IStrategy[]} = {};

    for (let i = 0; i < savedCalcs.length; i++)
        for (const key in savedCalcs[i]){
            if (sortedCalcs[key] === undefined)
                sortedCalcs[key] = [];
            let sum = 0;
            for (let z = 1; z < savedCalcs[i][key].length; z++){
                sum += savedCalcs[i][key][z].count;
            }
            sortedCalcs[key].push({charRep: savedCalcs[i][key][0].charRep, count: sum})
        }
    for (const key in sortedCalcs){
        let sum = 0;
        for (let i = 0; i < sortedCalcs[key].length; i++)
            sum += sortedCalcs[key][i].count;
        calculatedTotalsExportable.push({charRep: key, count: sum})
    }

    if(calculatedTotalsExportable.length > 7){
        const pagesToGo = Math.ceil((calculatedTotalsExportable.length-7)/20);
        const pdfData2 = await fetch('./DA5811.pdf').then(res => res.arrayBuffer());
        const pdfDoc2 = await PDFDocument.load(pdfData2, { ignoreEncryption: true });
        for(let i = 0; i < pagesToGo && i < MAX_PAGES; i++) {
            const [pageTwo] = await pdfDoc.copyPages(pdfDoc2, [0])
            pdfDoc.insertPage((i+1), pageTwo);
        }

    }
    const form = pdfDoc.getForm()
    pdfDoc.registerFontkit(fontKit);
    const pages = pdfDoc.getPages()
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const cursiveFontData = await fetch('./Allura-Regular.ttf').then(res => res.arrayBuffer());
    const cursiveFont = await pdfDoc.embedFont(cursiveFontData);

    createNonFunctionalFields(form, helveticaFont, pages, cursiveFont);
    createFunctionalTextFields(form, helveticaFont, pages);
    if(calculatedTotalsExportable.length > 7) createSecondaryPagesTextFields(form, helveticaFont, pages, calculatedTotalsExportable.length);
    fillTextFields(form, pages, calculatedTotalsExportable);

    try{
    const pdfBytes = await pdfDoc.save({ });
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    }
    catch{
        return 1;
    }
}

function buttonCreator(
    sorted_chapters : IChapter[],
    tooSmall : boolean,
    setChapterSelected : React.Dispatch<React.SetStateAction<string | undefined>>,
    tooBig : boolean,
    classBigAdd : string
) {
    const elements: ReactElement[] = [];
        for (let i = 0; i < sorted_chapters.length; i++){
            if (sorted_chapters[i].image && !tooSmall && !tooBig) {
                elements.push(
                    <div className="btndiv">
                        <IconButton sx={{    
                            display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                            borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                            marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                            }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                        </IconButton>
                        <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                    </div>
                );
            }
            //4k monitor
            else if (sorted_chapters[i].image && tooBig && !tooSmall)
            elements.push(
                <div className={`btndiv${classBigAdd}`}>
                    <IconButton sx={{    
                        display: "flex", width: "6.51vw", height: "11.574vh", "&:hover": { width: "7.51vw", height: "12.574vh" }, flexDirection: "column", alignItems: "center", gap: "8px",
                        borderRadius: "12px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                        marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                        }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                    </IconButton>
                    <div className={`branchname${classBigAdd}`} onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                </div>
            );   
            else if (sorted_chapters[i].image && tooSmall)
                elements.push(
                    <div className="btndiv">
                        <IconButton sx={{    
                            display: "flex", width: "78px", height: "78px", "&:hover": { border: "2px solid #FDD017" }, flexDirection: "column", alignItems: "center", gap: "8px",
                            borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                            marginLeft: "auto", marginRight: "auto", backgroundImage: `url(${sorted_chapters[i].image})`, backgroundSize: 'contain'
                            }}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                        </IconButton>
                        <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                    </div>
                );
            else if (!sorted_chapters[i].image && tooSmall)
                elements.push(
                    <div className="btndiv">
                        <IconButton sx={{    
                            display: "flex", width: "78px", height: "78px", "&:hover": { border: "2px solid #FDD017" }, flexDirection: "column", alignItems: "center", gap: "8px",
                            borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                            marginLeft: "auto", marginRight: "auto"}}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                            <CompressIcon/>
                        </IconButton>
                        <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                    </div>
                );
            else
                elements.push(
                    <div className="btndiv">
                        <IconButton sx={{    
                            display: "flex", width: "78px", height: "78px", "&:hover": { width: "87px", height: "87px" }, flexDirection: "column", alignItems: "center", gap: "8px",
                            borderRadius: "8px", border: "1px solid #D49F00", color: "black", backgroundColor: "#EFEFEF", fontSize: "10px",
                            marginLeft: "auto", marginRight: "auto"}}onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>
                            <CompressIcon/>
                        </IconButton>
                        <div className="branchname" onClick={() => {setChapterSelected(sorted_chapters[i].name)}}>{sorted_chapters[i].name}</div>
                    </div>
                );
        }
    return(elements)
}

function titleMaker(
    chapterSelected: string | undefined,
    catValue : Option | null,
    weaponCount : number,
    iterationCount : number,
    eveValue : readonly Option[],
    savedTitles : ReactElement<any, string | React.JSXElementConstructor<any>>[],
    setSavedTitles : (value: React.SetStateAction<ReactElement<any, string | React.JSXElementConstructor<any>>[]>) => void,
    tooBig : boolean
) {
    let tooBigStyle = {}
    if (tooBig) {
        tooBigStyle = {
            fontSize : '0.85vw',
            padding:'20px'
        }
    } 
    const title: ReactElement = 
    <List dense={false} sx={{backgroundColor:'#eaeaea', borderBottom: "2px solid #bababa", ...tooBigStyle}}>
        <ListItem sx={{padding: '5px', lineHeight:'0.85vw'}}>{chapterSelected} - {catValue?.value}</ListItem>
        <ListItem >{weaponCount} Weapon(s) x {iterationCount} Iteration(s)</ListItem>
        {eveValue.map((chapter, i) => (
            <ListItem key={i}>{chapter.value}</ListItem>
        ))}
    </List>
    setSavedTitles([...savedTitles, title]);
}

export { 
    createEventList, 
    createEventTagList, 
    createWeaponTagList,
    createWeaponsList,
    createEventTagsList,
    createCategoriesList,
    calculate,
    quickLinkMaker,
    modifyPdf,
    buttonCreator,
    titleMaker
 };